<script setup="setup" lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import NullableField from "../components/NullableField.vue";
import { sanitizeUrl } from "@braintree/sanitize-url";
import RoutineForm from "../components/RoutineForm.vue";
import QueryContext from "../models/queryContext";
import { snakecase } from "stringcase";
import { useAuth } from "@/stores/auth";
import { useWorkspace } from "@/stores/workspace";
import { useToast } from "vue-toast-notification";
import VButton from "../components/VButton.vue";
import VIcon from "../components/VIcon.vue";
import VModal from "../components/VModal.vue";
import VSpacer from "../components/VSpacer.vue";
import VTable from "../components/VTable.vue";
import VTextField from "../components/VTextField.vue";
import "vue-toast-notification/dist/theme-default.css";
import WorkspaceApi from "../api/workspace";

const props = defineProps<{
  slug: string;
}>();

const authStore = useAuth();
const workspaceStore = useWorkspace();
const router = useRouter();
const toast = useToast();

let debounceTimer = setTimeout(() => "", 500);
const headers = [
  { text: "Name", value: "name" },
  { text: "Days Remaining", value: "daysRemaining" },
  { text: "Next Check", value: "nextWardenAt" },
  { text: "Last Check", value: "wardenedAt" },
  { text: "Cycle (Days)", value: "cycleDays" },
];
const limit = ref(50);
const offset = ref(0);
const routineDialogOpen = ref(false);
const q = ref("");
const selectedRoutineIds = ref([]);
const sortBy = ref(["daysRemaining"]);
const sortDir = ref(["asc"]);
const total = ref(0);
const routines = ref([]);

onMounted(() => {
  fetchAllRoutines();
});

const activeWorkspaceId = computed(() => {
  let org = workspaceStore.getWorkspaceBySlug(props.slug);
  return !!org && !!org.id ? org.id : "";
});

const isRoutineSelected = computed(() => {
  return selectedRoutineIds.length > 0;
});

watch(
  () => authStore.activeWorkspace,
  (newValue, _oldValue) => {
    fetchAllRoutinesForWorkspace(newValue);
  },
);

watch(limit, () => {
  fetchAllRoutines();
});

watch(offset, () => {
  fetchAllRoutines();
});

watch(sortBy, () => {
  offset.value = 0;
  fetchAllRoutines();
});

watch(sortDir, () => {
  offset.value = 0;
  fetchAllRoutines();
});

watch(q, () => {
  clearTimeout(debounceTimer);

  debounceTimer = setTimeout(() => {
    offset.value = 0;
    fetchAllRoutines();
  }, 500);
});

function classForRoutine(routine: Routine) {
  return routine.daysRemaining > 0 ? "" : "red--text";
}

function didSelect(routine: Routine) {
  router.push(`/${props.slug}/routines/${routine.id}`);
}

function dismissDialog() {
  routineDialogOpen.value = false;
}

function fetchAllRoutines() {
  fetchAllRoutinesForWorkspace(props.slug);
}

function routineDetailUrl(routine) {
  return sanitizeUrl("/" + props.slug + "/routines/" + routine.id);
}

async function fetchAllRoutinesForWorkspace(slug: string) {
  const context = new QueryContext(
    limit.value,
    offset.value,
    sortBy.value.map(snakecase),
    sortDir.value,
    q.value,
  );
  let response = await WorkspaceApi.allRoutines(slug, context);
  routines.value = response.data.routines;
  total.value = response.data.pagination.total;
}

function openRoutineDialog() {
  routineDialogOpen.value = true;
}

function onSaveRoutine() {
  routineDialogOpen.value = false;
  fetchAllRoutines();
  toast.success("Routine created.", { position: "bottom" });
}
</script>

<template>
  <div class="page">
    <div class="heading">
      <h1 class="inline">Routines</h1>
      <VSpacer />
      <VButton v-if="isRoutineSelected" class="red white--text">
        <VIcon class="left" icon="close" />
        <span>Delete Selected</span>
      </VButton>
      <VButton class="purple white--text" @click="openRoutineDialog()">
        <VIcon class="left" icon="add" />
        <span>Create</span>
      </VButton>
    </div>
    <VTextField
      v-model="q"
      placeholder="Search routines..."
      class="mb-1 mt-1"
    />
    <VTable
      v-model:limit="limit"
      v-model:offset="offset"
      v-model:sort-by="sortBy"
      v-model:sort-dir="sortDir"
      v-model:total="total"
      :headers="headers"
      :items="routines"
      description="Table of routines"
      @select-row="didSelect($event)"
    >
      <template #[`item.name`]="{ item }">
        <NullableField
          v-model="item.name"
          :href="routineDetailUrl(item)"
          @click.stop=""
        />
      </template>
      <template #[`item.daysRemaining`]="{ item }">
        <NullableField
          v-model="item.daysRemaining"
          :class="classForRoutine(item)"
        />
      </template>
      <template #[`item.nextWardenAt`]="{ item }">
        <NullableField v-model="item.nextWardenAt" type="datetime" />
      </template>
      <template #[`item.wardenedAt`]="{ item }">
        <NullableField v-model="item.wardenedAt" type="datetime" />
      </template>
      <template #[`item.cycleDays`]="{ item }">
        <NullableField v-model="item.cycleDays" />
      </template>
    </VTable>
    <VModal v-model="routineDialogOpen">
      <RoutineForm
        :workspace-id="activeWorkspaceId"
        @save="onSaveRoutine()"
        @cancel="dismissDialog()"
      />
    </VModal>
  </div>
</template>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}

.heading {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  gap: 1em;
}
</style>
