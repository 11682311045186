<script setup lang="ts">
import { computed } from "vue";
import { DateTime } from "luxon";
import { sanitizeUrl } from "@braintree/sanitize-url";

export interface Props {
  href?: string;
  modelValue?: string | number;
  type?: string;
}

const props = withDefaults(defineProps<Props>(), {
  type: "string",
});

const displayValue = computed(() => {
  if (props.type === "datetime") {
    return datetimeString(props.modelValue);
  } else if (props.type === "datetime-relative") {
    return daysAgoString(props.modelValue);
  } else {
    return props.modelValue;
  }
});

const sanitizedHref = computed(() => {
  const url = sanitizeUrl(props.href);
  return url == "about:blank" ? false : url;
});

const isDefined = computed(() => {
  return props.modelValue !== undefined && props.modelValue !== null;
});

function datetimeString(datetime: string): string {
  return DateTime.fromISO(datetime, { zone: "utc" })
    .toLocal()
    .toLocaleString(DateTime.DATE_MED);
}

function daysAgoString(datetime: string): string {
  return DateTime.fromISO(datetime, { zone: "utc" }).toLocal().toRelative();
}
</script>

<template>
  <a v-if="sanitizedHref && isDefined" :href="sanitizedHref" target="_blank">{{
    displayValue
  }}</a>
  <span v-else-if="isDefined" v-text="displayValue" />
  <span v-else class="small grey--text">(none)</span>
</template>

<style lang="scss" scoped>
.left {
  margin-right: 0.3em;
}

.right {
  margin-left: 0.3em;
}
</style>
