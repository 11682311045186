import ApiResponse from "../models/apiResponse";
import AuthApi from "../api";
import { defineStore } from "pinia";
import ProfileProperties from "../models/profileProperties";
import User from "../models/user";
import Util from "../api/util";

const ACTIVE_WORKSPACE = "activeWorkspace";
const PROFILE = "profile";

export const useAuth = defineStore("auth", {
  state: () => {
    return {
      activeWorkspace: localStorage.getItem(ACTIVE_WORKSPACE),
      isLoading: false,
      errorMessage: null as string | null,
      profile: User.fromLocalStorageJSON(
        JSON.parse(localStorage.getItem(PROFILE) ?? "{}"),
      ) as User | null,
      token: Util.getToken() as string | null,
    };
  },
  getters: {
    avatarUrl(): string {
      return !!this.profile && this.profile.avatar
        ? this.profile.avatar.url
        : undefined;
    },
    isAuthenticated(): boolean {
      return this.token !== null;
    },
    getProfile: (state) => {
      return () => state.profile;
    },
    hasError(): boolean {
      return this.errorMessage !== null;
    },
  },
  actions: {
    async fetchProfile(): Promise<ApiResponse> {
      try {
        let response = await AuthApi.fetchProfile();
        this.profile = response.data as User;
        localStorage.setItem(PROFILE, JSON.stringify(this.profile));
      } catch (error) {
        // Nothing yet.
      }
    },
    async login(email: string, password: string): Promise<ApiResponse> {
      this.isLoading = true;
      this.errorMessage = null;

      try {
        let response = await AuthApi.login(email, password);
        this.token = response.data.token;
        Util.saveToken(response.data.token);
        this.profile = response.data.user as User;
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    selectActiveWorkspace(slug: string) {
      this.activeWorkspace = slug;
      localStorage.setItem(ACTIVE_WORKSPACE, slug);
    },
    async signup(
      name: string,
      email: string,
      password: string,
    ): Promise<ApiResponse> {
      this.isLoading = true;
      this.errorMessage = null;

      try {
        let response = await AuthApi.register(name, email, password);
        this.token = response.data.token;
        Util.saveToken(response.data.token);
        this.profile = response.data.user as User;
        selectActiveWorkspace(response.data.workspace.slug);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async logout(): Promise<void> {
      try {
        await AuthApi.logout();
      } catch (error) {
        console.error(error);
      } finally {
        Util.removeToken();
        localStorage.removeItem(PROFILE);
        this.token = null;
        this.profile = new User();
        this.activeWorkspace = undefined;
        localStorage.removeItem(ACTIVE_WORKSPACE);
      }
    },
    async requestPasswordReset(email: string): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        await AuthApi.requestPasswordReset(email);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async resetPassword(token: string, password: string): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        await AuthApi.resetPassword(token, password);
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async updateProfile(properties: ProfileProperties): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await AuthApi.updateProfile(properties);
        this.profile = response.data.user as User;
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
    async updateProfileAvatar(file: File): Promise<ApiResponse> {
      this.isLoading = true;

      try {
        let response = await AuthApi.updateProfileAvatar(file);
        this.profile = response.data.user as User;
      } catch (error) {
        // Nothing yet.
      } finally {
        this.isLoading = false;
      }
    },
  },
});
