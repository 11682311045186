import ApiResponse from "../models/apiResponse";
import axios from "axios";
import { useAuth } from "@/stores/auth";
import router from "../router";
import ProfileProperties from "../models/profileProperties";
import Util from "./util";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

axios.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const toast = useToast();

    if (!!error.response.status) {
      if (error.response.status === 401) {
        useAuth().logout();
        router.push({ name: "Login" });
      } else {
        toast.error(JSON.stringify(error), { position: "bottom" });
      }

      throw new Error(JSON.stringify(error.response));
    }

    toast.error(JSON.stringify(error), { position: "bottom" });
    throw error;
  },
);

export default {
  async fetchProfile(): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/profile");

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
  async fetchSessions(): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/profile/sessions");

    return await axios.get<ApiResponse>(url, {
      headers: Util.authHeaders(),
    });
  },
  async login(email: string, pass: string): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/auth/login");
    const params = { email: email, password: pass };

    return await axios.post<ApiResponse>(url, params);
  },
  async logout(): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/auth/logout");

    return await axios.post<ApiResponse>(url, "", {
      headers: Util.authHeaders(),
    });
  },
  async register(
    name: string,
    email: string,
    pass: string,
  ): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/auth/register");
    const params = { name: name, email: email, password: pass };

    return await axios.post<ApiResponse>(url, params);
  },
  async requestPasswordReset(email: string | null): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/auth/forgot-password");
    const params = { email: email };

    return await axios.post<ApiResponse>(url, params);
  },
  async resetPassword(
    token: string | null,
    password: string | null,
  ): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/auth/password");
    const params = { token: token, password: password };

    return await axios.put<ApiResponse>(url, params);
  },
  async updateProfile(properties: ProfileProperties): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/profile");

    return await axios.put<ApiResponse>(url, properties, {
      headers: Util.authHeaders(),
    });
  },
  async updateProfileAvatar(file: File): Promise<ApiResponse> {
    const url = Util.apiUrl("/api/v1/profile/avatar");
    const params = new FormData();
    params.append("avatar", file);

    return await axios.put<ApiResponse>(url, params, {
      headers: Util.authHeaders(),
    });
  },
};
